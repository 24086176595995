<template>
    <div
        class="banner banner--self-employed"
        :class="{
            'banner--visible': isVisible,
            'i18n--loading': i18nLoading
        }"
        >
        <div class="page__wrapper">
            <div class="banner__content">
                <div class="wrapper">
                    <h2>
                        {{ $t('title') }}
                    </h2>
                    <a
                        href="https://www.sberbank.ru/ru/svoedelo#freeservices"
                        target="_blank"
                        rel="noopener"
                        class="btn btn--lg"
                        :title="$t('btns.details')"
                        @click="closeBanner()"
                        >
                        {{ $t('btns.details') }}
                    </a>
                </div>
                <picture class="img">
                    <source srcset="@/assets/images/banner-self-employed.webp" type="image/webp">
                    <source srcset="@/assets/images/banner-self-employed.png" type="image/png">
                    <img src="@/assets/images/banner-self-employed.png" width="163" height="250" :alt="$t('title')">
                </picture>
            </div>
            <button
                class="btn btn--action"
                :title="$t('btns.close')"
                @click.prevent="closeBanner()"
                >
                <icon-close-thin />
            </button>
        </div>
    </div>
</template>

<script>

    import mixinI18n from '@/mixins/mixinI18n.js';
    import { defaultLang } from '@/i18n/components/banners/ElBannerSelfEmployed';
    import iconCloseThin from '@/components/icons/iconCloseThin';
    import { events } from '@/lib/events';
    import { getCookie, setCookie } from '@/lib/cookies.js';

    export default {
        name: 'ElBannerSelfEmployed',
        mixins: [
            mixinI18n
        ],
        components: {
            iconCloseThin
        },
        data() {
            return {
                isVisible: false,
                timeout: null
            }
        },
        i18n: {
            messages: defaultLang
        },
        methods: {
            closeBanner() {
                setCookie('cookieSelfEmployed', true, {
                    expires: 3600*24,
                    path: '/'
                });
                this.isVisible = false;
            },
            evbusBannerClosehandler(data){
                clearTimeout(this.timeout);
                this.closeBanner();
            },
            async loadI18nFile(lang){
                let loadModule = () => import(`@/i18n/components/banners/ElBannerSelfEmployed/${lang}.js`);
                await loadModule().then(module => {
                    this.$i18n.setLocaleMessage(lang, module.default);
                });
            }
        },
        mounted() {
            this.$evbus.$on(events.EV_BANNER_SELF_EMPLOYED_CLOSE, this.evbusBannerClosehandler);
            this.$evbus.$on(events.EV_LOGIN, this.evbusBannerClosehandler);

            let cookie = getCookie('cookieSelfEmployed');
            if( !cookie && !this.$store.getters.isLoggedIn ){
                this.timeout = setTimeout(() => {
                    this.isVisible = true;
                }, 1000);
            }
        },
        beforeDestroy(){
            this.$evbus.$off(events.EV_BANNER_SELF_EMPLOYED_CLOSE, this.evbusBannerClosehandler);
            this.$evbus.$off(events.EV_LOGIN, this.evbusBannerClosehandler);
            clearTimeout(this.timeout);
        }
    }
</script>
