<template>
    <div class="page__body" :class="{'i18n--loading': i18nLoading}">
                                                            
                                         
                  
        <el-header></el-header>
            <main class="page__main main">
                                                         
                <el-banner-not-authorized-customer v-if="$store.getters.isNotAuthorizedCustomer" />
                          
                                                  
                <el-banner-law-docs-refused v-if="$store.getters.userRefuseLawDocs" />
                          
                                                                
                <el-banner-user-is-freezed v-if="$store.getters.userIsFreezed" />
                          
                <router-view></router-view>
            </main>
        <el-footer></el-footer>

                                               
        <el-banner-cookie />
                  
                                                      
        <el-banner-self-employed />
                  

                                          
        <el-popup-law-docs ref="law_docs" :ignore-evbus-close="true"/>
                  

    </div>
</template>

<script>

    import { events } from '@/lib/events';
    import mixinI18n from '@/mixins/mixinI18n.js';
    import ElHeader from '@/components/ElHeader';
    import ElFooter from '@/components/ElFooter';
                                                        
                                                                                             
              
                                           
    import ElBannerCookie from '@/components/banners/ElBannerCookie';
              
                                                  
    import ElBannerSelfEmployed from '@/components/banners/ElBannerSelfEmployed';
              
                                             
    import ElBannerNotAuthorizedCustomer  from '@/components/banners/ElBannerNotAuthorizedCustomer';
              
                                      
    import ElBannerLawDocsRefused from '@/components/banners/ElBannerLawDocsRefused';
              
                                                    
    import ElBannerUserIsFreezed from '@/components/banners/ElBannerUserIsFreezed';
              
                                      
    import ElPopupLawDocs from '@/components/popups/ElPopupLawDocs';
              

    export default {
        name: 'App',
        mixins: [
            mixinI18n
        ],
        components: {
            ElHeader,
            ElFooter,
                                                                
                                       
                      
                                                   
            ElBannerCookie,
                      
                                                          
            ElBannerSelfEmployed,
                      
                                                     
            ElBannerNotAuthorizedCustomer,
                      
                                              
            ElBannerLawDocsRefused,
                      
                                                            
            ElBannerUserIsFreezed,
                      
                                              
            ElPopupLawDocs
                      
        },
        data() {
            return {

            }
        },
        computed: {
            isSafari() {
                return (navigator.userAgent.indexOf('Safari') != -1) && (navigator.userAgent.indexOf('Chrome') == -1);
            }
        },
        watch:{
            $route(newVal, oldVal){
                this.$evbus.$emit(events.EV_APP_CLOSE_ALL_WINDOWS);
                document.querySelector('.page').classList.remove('page--noscroll');
                if( oldVal.params.pagen !== undefined && oldVal.params.pagen != 1 && newVal.params.pagen === undefined ){
                    this.$evbus.$emit(events.EV_APP_PAGE_PAGEN_RESET);
                }
                let i18nKey = newVal.name.replaceAll(/-/g,'_'),
                    pageTitle = this.$t(`viewTitles.${i18nKey}`),
                    titleTranslationExists = pageTitle.indexOf('viewTitles') !== 0;
                document.title = titleTranslationExists ? `${pageTitle} — ${this.$store.state.project.title}` : this.$store.state.project.title;
            }
        },
        methods: {
            lockPageScroll() {
                if( this.isSafari ){
                    this.scrollPos = {
                        x: window.scrollX,
                        y: window.scrollY
                    }
                }
                document.querySelector('.page').classList.add('page--noscroll');
            },
            unlockPageScroll() {
                document.querySelector('.page').classList.remove('page--noscroll');
                if( this.isSafari && this.scrollPos){
                    window.scrollTo( this.scrollPos.x, this.scrollPos.y );
                }
            },
            evbusToggleScrollbarHandler(data){
                data ? this.unlockPageScroll() : this.lockPageScroll();
            },
            evbusLoginHandler(data){
                this.$refs['law_docs']?.pollDocs();
            },
            evbusLogoutHandler(data){
                this.$refs['law_docs']?.stopPolling();
            },
            async loadI18nFile(lang){
                let loadModule = () => import(`@/i18n/${lang}.js`);
                await loadModule().then(module => {
                    this.$i18n.setLocaleMessage(lang, module.default);
                });
            },
            checkI18nLocale(){
                let lang = this.$store.getters.getLang;
                document.querySelector('html').setAttribute('lang', lang);
                if (this.$i18n.locale !== lang) {
                    this.i18nLoading = true;
                    this.loadI18nFile(lang).then( () => {
                        this.$i18n.locale = lang;
                        document.querySelector('html').setAttribute('lang', lang);
                    }).finally(() => {
                        this.i18nLoading = false;
                    });
                }
            },
            evbusShowToastHandler(evt){
                let msg = evt.detail.i18n ? this.$t(evt.detail.msgKey) : evt.detail.msg;
                this.$toast[evt.detail.type](msg);
            },
            onPageVisibilityChange(evt){
                this.$evbus.$emit(events.EV_PAGE_VISIBILITY_CHANGE, document.visibilityState === 'visible');
            },
            handleEventListeners(addEvents = true){
                if( addEvents ){
                    document.addEventListener('visibilitychange', this.onPageVisibilityChange);
                }else{
                    document.removeEventListener('visibilitychange', this.onPageVisibilityChange);
                }
            }
        },
        mounted() {
            this.$evbus.$on(events.EV_APP_TOGGLE_PAGE_SCROLLBAR, this.evbusToggleScrollbarHandler);
            this.$evbus.$on(events.EV_LOGIN, this.evbusLoginHandler);
            this.$evbus.$on(events.EV_LOGOUT, this.evbusLogoutHandler);
            window.addEventListener(events.EV_APP_SHOW_TOAST, this.evbusShowToastHandler);
            this.handleEventListeners(true);
        },
        beforeDestroy(){
            this.$evbus.$off(events.EV_APP_TOGGLE_PAGE_SCROLLBAR, this.evbusToggleScrollbarHandler);
            this.$evbus.$off(events.EV_LOGIN, this.evbusLoginHandler);
            this.$evbus.$off(events.EV_LOGOUT, this.evbusLogoutHandler);
            window.removeEventListener(events.EV_APP_SHOW_TOAST, this.evbusShowToastHandler);
            this.handleEventListeners(false);
        }
    }
</script>
