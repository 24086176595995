<template>
    <div
        class="banner banner--cookie"
        :class="{
            'banner--visible': isVisible,
            'i18n--loading': i18nLoading
        }"
        >
        <div class="page__wrapper">
            <div class="banner__content">
                {{ $t('text[0]', ['&nbsp;']) }} <a :href="$store.state.docs.politikaObrabotkiPd" target="_blank" class="link link--primary">{{ $t('text[1]', ['&nbsp;']) }}</a> {{ $t('text[2]', ['&nbsp;']) }} <a :href="$store.state.docs.soglasieCookie" target="_blank" class="link link--primary">{{ $t('text[3]', ['&nbsp;']) }}</a>. {{ $t('text[4]', ['&nbsp;']) }}.
            </div>
            <button
                class="btn btn--action"
                :title="$t('btns.close')"
                @click.prevent="closeBanner()"
                >
                <icon-close-thin />
            </button>
        </div>
    </div>
</template>

<script>

    import mixinI18n from '@/mixins/mixinI18n.js';
    import { defaultLang } from '@/i18n/components/banners/ElBannerCookie';
    import iconCloseThin from '@/components/icons/iconCloseThin';
    import { getCookie, setCookie } from '@/lib/cookies.js';

    export default {
        name: 'ElBannerCookie',
        mixins: [
            mixinI18n
        ],
        props: {

        },
        components: {
            iconCloseThin
        },
        data() {
            return {
                isVisible: false
            }
        },
        i18n: {
            messages: defaultLang
        },
        methods: {
            closeBanner() {
                setCookie('cookieAccepted', true, {
                    expires: 3600*24,
                    path: '/'
                });
                this.isVisible = false;
            },
            async loadI18nFile(lang){
                let loadModule = () => import(`@/i18n/components/banners/ElBannerCookie/${lang}.js`);
                await loadModule().then(module => {
                    this.$i18n.setLocaleMessage(lang, module.default);
                });
            }
        },
        mounted() {
            let cookie = getCookie('cookieAccepted');
            if( !cookie ){
                this.isVisible = true;
            }
        }
    }
</script>
