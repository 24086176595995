import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import localStorage from 'store';
import { getDigits, getFio } from '@/lib/libUtils';
import createMutationsSharer from 'vuex-shared-mutations';
import { events } from '@/lib/events';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
let pdfSubFolder = 'default';
                                      
                          
          

export default new Vuex.Store({
    strict: debug,
    plugins: [
        createMutationsSharer({
            predicate: [
                "setIsLoggedIn",
                "setUserInfo",
                "setBalance",
                "setAccessToken",
                "setAccessTokenExpires",
                "setFingerprint",
                "setUpdatingTokens"
            ]
        }),
        ... debug ? [createLogger()] : []
    ],

    state: {
        project: {
            title: 'ABC Elementary',
            shortTitle: 'Elementary',
            hotLinePhone: '8 980 899-08-99',
            mainEmail: 'elementary@activebc.ru',
            helpEmail: 'help@activebc.ru',
            supportEmail: 'elementary@activebc.ru',
            apiBase: window.VUE_APP_API || process.env.VUE_APP_API,
            landingFront: 'https://elementary.activebc.ru/', //process.env.VUE_APP_LANDING
            sandboxUrl: 'https://sandbox-api-elementary.activebc.ru/',
            apiVersion: '0.1.0',
            updateNotificationsMin: 5,
            updateDocsMin: 5
        },
        auth:{
            accessToken: null,
            accessTokenExpires: null,
            fingerprint: null
        },
        docs: {
            politikaObrabotkiPd:            `/files/${pdfSubFolder}/politika_obrabotki_pd.pdf?v=2`,
            soglasieRegistraciyaZakazchik:  `/files/${pdfSubFolder}/soglasie_registraciya_zakazchik.pdf?v=2`,
            soglasieSamozanyatyjPeredacha:  `/files/${pdfSubFolder}/soglasie_samozanyatyj_peredacha.pdf?v=2`,
            soglasieSamozanyatyjObrabotka:  `/files/${pdfSubFolder}/soglasie_samozanyatyj_obrabotka.pdf?v=2`,
            soglasieCookie:                 `/files/${pdfSubFolder}/soglasie_cookie.pdf?v=2`,
            ofertaSamozanyatogoNaRazmetku:  `/files/${pdfSubFolder}/oferta_samozanyatogo_na_razmetku.pdf?v=2`,
            userManualPdfHref:              '/files/user_manual.pdf?v=2',
            markerManualPdfHref:            '/files/marker_manual.pdf?v=2',
            userManualDocxHref:             '/files/user_manual.docx?v=2',
            markerManualDocxHref:           '/files/marker_manual.docx?v=2'
        },
        updatingTokens: false,
        annotatorNotesCopy: null,
        originalRoute: null,
        isLoggedIn: false,
        user: null,
        balance: null,
        lang: localStorage.get('preferencies')?.lang || process.env.VUE_APP_DEFAULT_LANG
    },

    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn;
        },
        getBearer(state){
            return `Bearer ${state.auth.accessToken}`;
        },
        apiSettings(state) {
            return {
                base: state.project.apiBase,
                version: state.project.apiVersion
            }
        },
        hotLinePhone2Call(state) {
            return getDigits(state.project.hotLinePhone);
        },
        fioFormatted(state) {
            return (
                state.isLoggedIn && state.user
                    ? getFio(
                        state.user.first_name,
                        state.user.last_name,
                        state.user.patronym_name
                    )
                    : ''
            );
        },
        accessToken(state){
            return state.auth.accessToken;
        },
        accessTokenExpires(state){
            return state.auth.accessTokenExpires;
        },
        fingerprint(state){
            return state.auth.fingerprint;
        },
        updatingTokens(state){
            return state.updatingTokens;
        },
        annotatorNotesCopy(state){
            return state.annotatorNotesCopy;
        },
        originalRoute(state){
            return state.originalRoute;
        },
        userLogo(state){
            let resp = require('@/assets/images/userpic.png');
            if (state.user && state.user.logo ){
                resp = state.user.logo;
            }
            return resp;
        },
        userRefuseLawDocs(state) {//отказался подписывать документы
            let resp = false;
            if (state.user && state.user.status_id == 4) {
                resp = true;
            }
            return resp;
        },
        userIsFreezed(state) {//временно нет доступа к выполнению заданий
            let resp = false;
            if (state.user && state.user.status_id == 5) {
                resp = true;
            }
            return resp;
        },
        isNotAuthorizedCustomer(state) {//заказчик который не подписал документы и/или не заполнил реквизиты
            let resp = false;
            if (state.user && state.user.roles.includes('ROLE_CUSTOMER') && state.user.is_authorized === false) {
                resp = true;
            }
            return resp;
        },
        isAdmin(state){
            return state.user && state.user.roles.includes('ROLE_ADMIN');
        },
        isCustomer(state){
            return state.user && state.user.roles.includes('ROLE_CUSTOMER');
        },
        isTerbank(state){
            return state.user && state.user.roles.includes('ROLE_TERBANK');
        },
        isSelfEmployed(state){
            return state.user && state.user.roles.includes('ROLE_OUTER_MARKER');
        },
        isDebtor(state){
            return state.user && state.user.roles.includes('ROLE_DEBTOR');
        },
        isDeveloper(state){
            return state.user && state.user.roles.includes('ROLE_DEVELOPER');
        },
        isAbcFinance(state){
            return state.user && state.user.roles.includes('ROLE_ABC_FINANCE');
        },
        isAbtVacancy(state){
            return state.user && state.user.roles.includes('ROLE_ABT_VACANCY');
        },
        isMultiTeamUser(state){
            return Array.isArray(state.user?.team_uuid) && state.user.team_uuid.length > 1 ? true : false;
        },
        user(state) {
            return state.user || {};
        },
        userTeamUuid(state){
            return Array.isArray(state.user?.team_uuid) ? state.user.team_uuid[0] : null;
        },
        balance(state) {
            return state.balance || {}
        },
        isTouchDevice() {
            return (
                !!(typeof window !== 'undefined' &&
                ('ontouchstart' in window ||
                    (window.DocumentTouch &&
                    typeof document !== 'undefined' &&
                    document instanceof window.DocumentTouch))) ||
                !!(typeof navigator !== 'undefined' &&
                (navigator.maxTouchPoints || navigator.msMaxTouchPoints))
            );
        },
        getLang(state, value){
            return state.lang;
        }
    },

    mutations: {
        setIsLoggedIn(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        },
        setUserInfo(state, userInfo) {
            state.user = userInfo;
        },
        setBalance(state, balance) {
            state.balance = balance;
            localStorage.set('balance', balance);
        },
        setAccessToken(state, token){
            state.auth.accessToken = token;
        },
        setAccessTokenExpires(state, time){
            state.auth.accessTokenExpires = time;
        },
        setFingerprint(state, fingerprint){
            state.auth.fingerprint = fingerprint;
        },
        setUpdatingTokens(state, value){
            state.updatingTokens = value;
        },
        setAnnotatorNotesCopy(state, value){
            state.annotatorNotesCopy = value;
        },
        setOriginalRoute(state, value){
            state.originalRoute = value;
        },
        setLang(state, value){
            state.lang = value;
        }
    },

    actions: {
        loadUserInfo(context, {user, balance} = {}) {
            this.commit('setUserInfo', user);
            this.commit('setBalance', balance);
            this.commit('setIsLoggedIn', true);

            localStorage.set('user', user);
            localStorage.set('balance', balance);
            localStorage.set('isLoggedIn', true);
        },
        changeUserInfo(context, {user}){
            this.commit('setUserInfo', user);
            localStorage.set('user', user);
        },
        saveUserPreferencies(context, data){
            let current = localStorage.get('preferencies');
            localStorage.set('preferencies', { ...current, ...data});
        },
        loadUserPreferencies(context){
            return localStorage.get('preferencies');
        },
        addEntryToLocalStorageCache(context, data){
            if( data.key === undefined ){
                throw new Error('invalid entry key');
            }
            if( data.value === undefined ){
                throw new Error('invalid entry value');
            }
            if( !Number.isInteger(data.ttl) ){
                throw new Error('invalid entry ttl');
            }
            let entry = {},
                cache = localStorage.get('cache');
            entry[data.key] = {
                value: data.value,
                expires: new Date().getTime() + data.ttl
            }
            localStorage.set('cache', { ...cache, ...entry});
        },
        getEntryFromLocalStorageCache(context, key){
            if( key === null || key === undefined ){
                throw new Error('invalid cache key');
            }
            let cache = localStorage.get('cache'),
                needUpdate = false,
                now = new Date();
            if( cache ){
                Object.keys( cache ).map( key => {
                    let expires = new Date(cache[key].expires);
                    if( expires < now ){
                        delete cache[key];
                        needUpdate = true;
                    }
                });
                if( needUpdate ){
                    localStorage.set('cache', cache);
                }
            }
            return cache ? cache[key] || null : null;
        },
        loadUserInfoFromStorage(context) {
            let isLoggedIn = localStorage.get('refreshToken') !== undefined;
            if (isLoggedIn) {
                let userInfo = localStorage.get('user') || null,
                    balance = localStorage.get('balance') || null;
                if( userInfo && (!userInfo.roles || userInfo.id === undefined ) ){//if user data from localStorage is invalid
                    context.dispatch('clearUserInfo');
                    return false;
                }
                this.commit('setUserInfo', userInfo);
                this.commit('setBalance', balance);
                this.commit('setIsLoggedIn', true);
            }
            return isLoggedIn;
        },
        clearUserInfo(context) {
            if( this._vm && this._vm.$evbus ){//на случай если разлогинивание произошло при рефреше токенов вызываем EV_LOGOUT чтобы стопнуть запросы отправляемые по интервалу
                this._vm.$evbus.$emit(events.EV_LOGOUT);
            }
            context.commit('setIsLoggedIn', false);
            context.commit('setAccessToken', null);
            context.commit('setAccessTokenExpires', null);
            context.commit('setUserInfo', null);
            context.commit('setBalance', null);

            localStorage.remove('isLoggedIn');
            localStorage.remove('refreshToken');
            localStorage.remove('user');
            localStorage.remove('balance');
        }
    }
});
