export default {
    btns: {
        login: 'Войти',
        log_out: 'Выйти',
        collapse: 'Свернуть',
        expand: 'Развернуть',
        hide: 'Скрыть',
        show: 'Показать',
        back: 'Назад',
        next: 'Далее',
        unblock: 'Разблокировать',
        block: 'Заблокировать',
        increase: 'Увеличить',
        decrease: 'Уменьшить',
        enable: 'Включить',
        disable: 'Выключить',
        create: 'Создать',
        delete: 'Удалить',
        upload: 'Загрузить',
        download: 'Скачать',
        sign: 'Подписать',
        refuse: 'Отказаться',
        save: 'Сохранить',
        cancel: 'Отмена',
        copy: 'Копировать',
        paste: 'Вставить',
        duplicate: 'Дублировать',
        edit: 'Редактировать',
        change: 'Изменить',
        update: 'Обновить',
        add: 'Добавить',
        open: 'Открыть',
        close: 'Закрыть',
        send: 'Отправить',
        skip: 'Пропустить',
        view: 'Просмотреть',
        clear: 'Очистить',
        instruction: 'Инструкция',
        scroll_top: 'Наверх',
        top_up: 'Пополнить',
        register: 'Зарегистрироваться',
        details: 'Подробнее',
        apply: 'Применить',
        moderate: 'Проверить',
        activate: 'Активировать',
        launch: 'Запустить',
        get: 'Получить',
        reorder: 'Переместить',
        rename: 'Переименовать',
        move_up: 'Переместить выше',
        move_down: 'Переместить ниже',
        toggle_view: 'Переключить вид',
        change_password: 'Сменить пароль',
        expand_fullscreen: 'Развернуть на весь экран',
        sber_id: 'Войти по Сбер ID{0}{0}{0}{0}{0}',
        sber_team_id: 'Войти по Sber Team ID',
        forgot_password: 'Забыли пароль?',
        ldap_login: 'Войти через доменную учётную запись',
        accept: 'Принять',
        decline: 'Отклонить',
        print: 'Распечатать',
        return: 'Вернуть',
        more: 'Еще',
        stop: 'Завершить',
        pause: 'Пауза',
        resume_recording: 'Продолжить запись'
    },
    operators: {
        gt: 'Больше чем',
        gt_short: '>',
        lt: 'Меньше чем',
        lt_short: '<',
        ne: 'Не равно',
        ne_short: '≠',
        eq: 'Равно',
        eq_short: '=',
        ge: 'Больше или равно',
        gte: '@:(operators.ge)',
        ge_short: '≥',
        gte_short: '@:(operators.ge_short)',
        le: 'Меньше или равно',
        lte: '@:(operators.le)',
        le_short: '≤',
        lte_short: '@:(operators.le_short)',
        and: 'И',
        or: 'Или',
        not: 'Не',
        btw: 'Между',
        in: 'Содержит',
        in_short: '∋',
        not_in: 'Не содержит',
        not_in_short: '∌',
        ine: 'Заполнен',
        ie: 'Не заполнен',
        is: 'Выбран',
        ins: 'Не выбран',
        hl: 'Аннотация заполнена',
        hnl: 'Аннотация не заполнена'
    },
    hotkeys: {
        play_pause: 'Воспроизвести/Пауза',
        stop: 'Остановить',
        volume_up: 'Увеличить громкость',
        volume_down: 'Уменьшить громкость',
        brightness_up: 'Увеличить яркость',
        brightness_down: 'Уменьшить яркость',
        zoom_in: 'Увеличить масштаб',
        zoom_out: 'Уменьшить масштаб',
        jump_forward: 'Перемотать вперед',
        jump_backward: 'Перемотать назад',
        jump_to_time: 'Перемотать ко времени',
        zoom_in_out_area: 'Увеличить/уменьшить масштаб',
        speed_up: 'Увеличить скорость',
        speed_down: 'Уменьшить скорость',
        add_tag: 'Добавить тег',
        add_tag_frame: 'Добавить метку',
        add_tag_interval: 'Отметить начало/конец сегмента',
        select_variant: 'Выбрать пункт',
        start_stop_record: 'Начать/остановить запись',
        pause_resume_record: 'Поставить на паузу/возобновить запись',
        push_btn: 'Нажать кнопку',
        rotate_cw: 'Повернуть по часовой',
        rotate_ccw: 'Повернуть против часовой',
        delete_selected: 'Удалить выбранное',
        toggle_guides: 'Показать/Скрыть направляющие',
        stop_drawing_poly: 'Завершить рисование ломаной/многоугольника',
        toggle_editing: 'Редактировать',
        toggle_clip: 'Дополнить/обрезать',
        change_poly_type: 'Замкнуть/Разъединить',
        drag_workspace: 'Переместить рабочую область',
        sam_undo: 'Удалить последнюю точку',
        sam_clear: 'Очистить',
        sam_apply: 'Закрепить',
        sam_toggle: 'Включить/Выключить режим AI разметки',
        toggle_link_endpoints: 'Включить/выключить связывание конечных точек',
        toggle_autoscroll: 'Включить/выключить автоматическую прокрутку',
        delete_recorded: 'Удалить записанное',
        settings: 'Настройки',
        skip_frames_backward: 'Перейти на {0} назад',
        skip_frames_forward: 'Перейти на {0} вперед',
        show_next: 'Следующий кадр',
        show_prev: 'Предыдущий кадр',
        center: 'Центрировать',
        change_brightness: 'Изменить яркость',
        undo_action: 'Отменить действие',
        redo_action: 'Повторить действие',
        toggle_notes_visibility: 'Показать/Скрыть разметку',
        toggle_selected_visibility: 'Показать/Скрыть выбранное',
        toggle_numbers: 'Показать/Скрыть номера',
        toggle_tags: 'Показать/Скрыть метки',
        draw_poly_by_shift: 'Ломаная/многоугольник через shift',
        toggle_occluded: 'Изменить "перекрытие" выбранного',
        toggle_eraser: 'Добавлять/удалять область',
        toggle_eraser_add: 'Добавлять область',
        toggle_eraser_remove: 'Удалять область',
        finalize_bitmap: 'Зафиксировать битовую маску'
    },
    viewTitles: {
        not_found: 'Страница не найдена',
        additional_custom_service: 'Перед выполнением задания необходимо заполнить форму',
        admin_dashboard: 'Панель администратора',
        analytics_dashboard_list: 'Аналитика',
        analytics_dashboard_view: 'Аналитика (результат)',
        logs: 'Логи',
        user_manual: 'Руководство',
        charts: 'Графики',
        offer: 'Оферта',
        payments: 'Выплаты',
        customer_payments: 'Оплаты заказчиков',
        customer_task_performers: 'Исполнители',
        customer: 'Профиль заказчика',
        admin_teams: 'Команды',
        admin_users: 'Пользователи',
        admin_users_balances: 'Загрузка баланса пользователей из тегми',
        admin_settings: 'Настройки',
        ui_kit: 'Компоненты',
        devtools: 'Devtools',
        admin_moderation: 'Модерация',
        admin_team_view: 'Команда',
        customer_help: 'Инструкция заказчика',
        customer_payment: 'Детали оплаты',
        error: 'Ошибка',
        payment_info: 'Статус платежа',
        pool_edit: 'Редактирование пула',
        projects: 'Проекты',
        report_constructor: 'Отчеты',
        reset_password: 'Смена пароля',
        skills: 'Навыки',
        view_answers_failed: 'Просмотр ответов',
        view_answers_pool: 'Просмотр ответов пула',
        view_answers: 'Просмотр ответов пользователя',
        jobs: 'Задания',
        profile: 'Профиль',
        user: 'Профиль пользователя',
        project_add: 'Новый проект',
        project_edit: 'Редактирование проекта',
        custom_service_queue: 'Задание',
        project: 'Проект',
        messenger: 'Сообщения',
        message: 'Чат',
        pool: 'Пул',
        openid_login: 'Авторизация приложения',
        openid_result: 'Результат авторизации по OpenID',
        api_doc: 'Документация',
        tagme: 'Отчеты'
    },
    popups: {
        add_user_skill: 'Добавление навыка пользователю',
        add_settings: 'Добавить настройку',
        edit_settings: 'Изменить настройку',
        change_password: 'Смена пароля',
        change_scope: 'Изменение области действия',
        edit_user_skill: 'Редактирование навыка пользователя',
        export_result: 'Файл выгрузки',
        law_docs: 'Для продолжения работы пожалуйста подпишите следующие документы',
        project_config: 'Ввод конфига',
        project_instruction: 'Инструкция (обновлена)',
        project_tempaltes: 'Выбор шаблона',
        refill_balance: 'Пополнение счета',
        report_settings: 'Настройки отчета',
        reset_password: 'Сброс пароля',
        user_access: 'Права доступа',
        users_with_skill: 'Пользователи с навыком',
        user_team: 'Команда пользователя',
        failed_answers_details: 'Доп. инфо',
        change_logo: 'Сменить аватар',
        automarkup_pool: 'Авторазметка пула',
        snippet_settings: 'Настройки сниппетов',
        add_team_tag: 'Создание команды экспертов',
        answers_analysis: 'Анализ ответов',
        team_balance: 'Пополнить баланс команды',
        team_edit: 'Редактирование команды',
        team_add: 'Новая команда',
        remarkup_answer: 'Отправить на доразметку',
        customer_experts: 'Эксперты команды',
        rename_pool: 'Переименовать пул'
    },
    lbls: {
        if: 'Если',
        then: 'То',
        from: 'с',
        to: 'по',
        days_short: 'д',
        hours_short: 'ч',
        minutes_short: 'м',
        seconds_short: 'с',
        milliseconds_short: 'мс',
        percent_short: '%',
        items_count_short: 'шт.',
        yes: 'Да',
        no: 'Нет',
        today: 'Сегодня',
        yesterday: 'Вчера',
        week: 'Неделя',
        month: 'Месяц',
        time: 'Время',
        min: 'Минимум',
        max: 'Максимум',
        step: 'Шаг',
        all: 'Все',
        all_time: 'Все время',
        by_days: 'По дням',
        by_hours: 'По часам',
        key: 'Ключ',
        value: 'Значение',
        action: 'Действие',
        comment: 'Комментарий',
        login: 'Логин',
        password: 'Пароль',
        balance: 'Баланс',
        balance_history: 'История баланса',
        status: 'Статус',
        source: 'Источник',
        result: 'Результат',
        results_total: 'Всего',
        sum: 'Сумма',
        information: 'Информация',
        operation: 'Операция',
        detailing: 'Детализация',
        period: 'Период',
        offer: 'Оферта',
        subitem: 'Подпункт',
        variant: 'Вариант',
        state: 'Состояние',
        width: 'Ширина',
        format: 'Формат',
        profile: 'Профиль',
        payment_account: 'Номер счета',
        correspondent_account: 'Корр. счет',
        description: 'Описание',
        date_add: 'Добавлен',
        marked_up: 'Размечено',
        is_availible: 'Доступно',
        moderated: 'Отмодерировано',
        preview: 'Предпросмотр',
        scope: 'Область действия',
        volume: 'Громкость',
        audio: 'Аудио',
        reference: 'Эталон',
        duration: 'Длительность',
        report_type: 'Тип отчета',
        result_title: 'Символьный код',
        is_required: 'Обязателен для заполнения',
        show_hidden: 'Скрытые',
        roles_group: 'Группа ролей',
        execution_history: 'История выполнения',
        skill_value: 'Уровень навыка',
        quick_search: 'Быстрый поиск',
        execution_time: 'Время на выполнение задания',
        consensus: 'Уровень согласованности',
        quality_control: 'Контроль качества',
        visible_for_markers: 'Будет видно исполнителям',
        new_message: 'Новое сообщение',
        payment_by_details: 'Оплата по реквизитам',
        balances_file: 'Файл с балансами пользователей',
        have_no_patronym: 'У пользователя нет отчества',
        name: 'Название',
        group_name: 'Название группы',
        team_name: 'Название команды',
        date: 'Дата',
        registration_date: 'Дата регистрации',
        payout_request_date: 'Дата запроса на выплату',
        country: 'Страна',
        city: 'Город',
        street: 'Улица',
        house: 'Дом',
        building : 'Корпус',
        flat: 'Квартира',
        office: 'Офис',
        address: 'Адрес',
        residense_adress: 'Адрес места жительства',
        region: 'Область/край',
        fio: 'ФИО',
        first_name: 'Имя',
        surname: 'Фамилия',
        patronymic: 'Отчество',
        phone: 'Телефон',
        email: 'Email',
        sector: 'Сектор',
        se_inn: 'ИНН самозанятого',
        pp_inn: 'ИНН физ. лица',
        inn: 'ИНН',
        bik: 'БИК',
        kpp: 'КПП',
        customer_type: 'Субъект права',
        legal_entity: 'Юр. лицо',
        physical_person: 'Физ. лицо',
        position: 'Должность',
        answer_id: 'ID ответа',
        user_id: 'ID пользователя',
        task_id: 'ID задания',
        revision: 'Редакция',
        revision_short: 'Ред.',
        filename: 'Имя файла',
        settings: 'Настройки',
        members: 'Участники',
        finance: 'Финансы',
        markers: 'Разметчики',
        data: 'Данные',
        actions: 'Действия',
        requisites: 'Реквизиты',
        prompt: 'Запрос',
        speed: 'Скорость',
        hotkeys: 'Горячие клавиши',
        notifications: 'Уведомления',
        supported_formats: 'Поддерживаемые форматы',
        supported_encodings: 'Поддерживаемые кодировки',
        to_moderate: '{0} на проверку',
        accomplished: 'Выполнено',
        empty_list: 'Список пуст',
        no_data: 'Нет данных',
        no_search_rezults: 'Нет результатов. Попробуйте изменить запрос.',
        search_placeholder: 'Введите запрос для поиска',
        select_placeholder: 'Выберите вариант',
        drop_file: 'Выберите файл или перетащите сюда',
        move_left: 'Сдвинуть влево',
        move_right: 'Сдвинуть вправо',
        unload_non_zero: 'Выгружать ненулевые',
        output_by: 'Выводить по',
        search_by: 'Искать по',
        sort_by: 'Сортировать по',
        sort_by_asc: 'Отсортировать по убыванию',
        sort_by_desc: 'Отсортировать по возрастанию',
        exclude_roles: 'Исключить роли',
        download_result: 'Скачать файл выгрузки',
        send_result_to: 'Отправить файл выгрузки на',
        hide_in_markup: 'Скрывать в разметке',
        send_notifications: 'Присылать уведомления (MSK)',
        create_service_chat: 'Написать пользователю',
        edit_answer: 'Редактировать ответ',
        add_rule: 'Добавить правило',
        loading: 'Загрузка',
        saving: 'Сохранение',
        updating: 'Обновление',
        creating: 'Создание',
        edit: 'Редактирование',
        newbie: 'Новичок',
        blocked: 'Заблокирован',
        file_not_found: 'Файл не найден',
        toast_changes_saved: 'Изменения сохранены',
        toast_file_if_uploaded: 'Файл загружен',
        toast_copy_to_clipboard: 'Скопировано в буфер обмена',
        toast_operation_completed: 'Операция успешно завершена',
        toast_operation_started: 'Операция запушена',
        toast_operation_errror: 'Произошла ошибка',
        tippy_residense_adress: 'район, город, населенный пункт, улица, дом, корпус, квартира',
        tippy_have_no_patronym: 'В случае если отчество отсутствует, отметьте чекбокс справа от поля ввода',
        confirm_delete_answer: 'Ответ будет удален, вы уверены?',
        confirm_leave_page: 'Несохраненные изменения будут утеряны. Вы действительно хотите покинуть страницу?',
        confirm_reset_changes: 'Все изменения будут отменены, вы уверены?',
        confirm_law_docs_refuse: 'При отказе от подписания документов вы не сможете выполнять задания. Отказаться от подписания?',
        confirm_delete_user_team: 'Команда данного пользователя будет удалена, восстановление невозможно!',
        confirm_delete: 'Вы подтвержаете удаление?',
        msg_console_details: 'детали выведены в консоль',
        msg_secure_auth: 'Это безопасно и удобно. Ваши данные защищены шифрованием',
        msg_login_freeze: 'Повторная попытка входа доступна через',
        msg_llc: 'ООО ПКО «АБК»',
        balance_reserved: 'Зарезервировано под готовые, но ещё не принятые задания',
        accounting_documents: 'Отчетные документы',
        my_documents: 'Мои документы',
        msg_politics_agreement: [
            'Я ознакомлен(а) с',
            'Политикой в отношении обработки персональных данных в @:(lbls.msg_llc)'
        ],
        msg_consent_agreement: [
            'Я даю своё',
            'согласие',
            '@:(lbls.msg_llc) на обработку моих персональных данных.'
        ],
        msg_project_templates_footnote: [
            'Если не найдете нужный вам шаблон - вы можете создать',
            'новый проект без шаблона'
        ],
        msg_file_not_found_footnote: [
            'Возможно, проблемы с интернетом, попробуйте позже.',
            'Если файл остается недоступен в течение долгого времени, то, пожалуйста, сообщите в техподдержку.'
        ],
        accept_all: [
            'Я соглашаюсь на обработку',
            'персональных данных'
        ]
    },
    shapes: {
        rect: 'Прямоугольник',
        circle: 'Круг',
        ellipse: 'Эллипс',
        line: 'Линия',
        polyline: 'Ломаная',
        polygon: 'Многоугольник',
        point: 'Точка',
        bitmap: 'Битовая маска'
    },
    elements: {
        text_title: 'Текст',
        text_description: 'Поле для вывода текста',
        checkbox_title: 'Чекбокс',
        checkbox_description: 'Используется для управления параметром с двумя состояниями',
        radio_title: 'Радио',
        radio_description: 'Используется для выбора одного значения из нескольких',
        audio_title: 'Аудио',
        audio_description: 'Используется для вывода аудио файлов',
        video_title: 'Видео',
        video_description: 'Используется для вывода видеофайлов',
        spectrogram_title: 'Сегментация аудио',
        spectrogram_description: 'Используется для разметки аудио',
        videoannotator_title: 'Сегментация видео',
        videoannotator_description: 'Используется для разметки видео',
        textannotator_title: 'Сегментация текста',
        textannotator_description: 'Используется для разметки текста',
        annotator_title: 'Сегментация изображений',
        annotator_description: 'Используется для разметки изображений',
        sequenceannotator_title: 'Сегментация видео кадров',
        sequenceannotator_description: 'Используется для разметки кадров извлеченных из видеофайла',
        linechart_title: 'Сегментация графиков',
        linechart_description: 'Используется для разметки наборов данных представленных множества точек (x, y)',
        image_title: 'Изображениe',
        image_description: 'Используется для вывода изображений',
        input_title: 'Поле ввода',
        input_description: 'Поле ввода дает возможность указать значение с помощью клавиатуры',
        recorder_title: 'Запись звука',
        recorder_description: 'Используется для записи аудио при помощи микрофона',
        videorecorder_title: 'Запись видео',
        videorecorder_description: 'Используется для записи видео при помощи камеры',
        inputset_title: 'Набор полей ввода',
        inputset_description: 'Набор полей ввода дает возможность указать массив значений с помощью клавиатуры',
        select_title: 'Список',
        select_description: 'Упорядоченный набор значений, в котором некоторое значение может встречаться более одного раза',
        hierselect_title: 'Многоуровневый список',
        hierselect_description: 'Упорядоченный набор, в котором значения могут быть сгруппированы',
        button_title: 'Кнопка',
        button_description: 'Элемент, с очерченной границей, нажатие на который приводит к некоему действию',
        file_title: 'Файл',
        file_description: 'Поле для загрузки файла',
        range_title: 'Диапазон',
        range_description: 'Позволяет определить значение, которое должно быть в пределах указанного промежутка',
        mushra_title: 'MUSHRA',
        mushra_description: 'MUSHRA(MUltiple Stimuli with Hidden Reference and Anchor) — тест для оценки качества работы алгоритмов сжатия звука с потерями.',
        gigablock_title: 'Гигаблок',
        gigablock_description: 'Поле для запросов в GigaChat',
        interval_title: 'Интервал',
        interval_description: 'Создает дополнительное пространство между элементами',
        group_input: 'Ввод',
        group_collection: 'Сбор данных',
        group_media: 'Медиа',
        group_segmentation: 'Сегментация',
        group_service: 'Служебные',
        group_algorithms: 'Алгоритмы'
    },
    task_status: {
        payed: 'Оплачено',
        accepted: 'Принято',
        partially_accepted: 'Принято (частично)',
        declined: 'Отклонено',
        expired: 'Истек срок',
        canceled: 'Отказ',
        failed: 'Заблокировано',
        in_progress: 'В процессе',
        progress: '@:(task_status.in_progress)',
        completed: 'Завершено',
        remarkup: 'Переразметка',
        waiting_moderation: 'Ожидает модерации'
    },
    ctor_actions: {
        vldtn_compare_value: 'Значение элемента',
        vldtn_compare_selected: 'Значение элемента',
        vldtn_every_value: 'Все {0}',
        vldtn_some_value: 'Хотя бы один {0}',
        vldtn_every_value_length: 'Длина каждого {0}',
        vldtn_some_value_length: 'Длина хотя бы одного {0}',
        vldtn_specific_value: 'Конкретный {0}',
        vldtn_playback_percent: 'Процент воспроизведения',
        vldtn_values_length: 'Кол-во {0}',
        vldtn_value_length: 'Длина значения',
        vldtn_checked_length: 'Кол-во выбранных',
        bhvr_change_visibility: 'Изменить видимость',
        bhvr_change_is_required: 'Изменить обязательность заполнения',
        bhvr_change_value: 'Изменить состояние',
        bhvr_change_playback_percent: 'Изменить мин. процент воспроизведения'
    },
    plural: {
        answer: 'Ответ | Ответы | Ответов',
        task: 'Задание | Задания | Заданий',
        filter: 'Фильтр | Фильтра | Фильтры',
        group: 'Группа | Группу | Группы',
        team: 'Команда | Команду | Команды',
        authorized_team: 'Авторизованная команда | Авторизованную команду | Авторизованные команды',
        next: 'Следующая | Следующий | Следующую',
        page: 'Страница | Страницу | Страницы',
        registration: 'Регистрация | Регистрацию | Регистрации',
        authorized: 'Авторизована | Авторизован | Авторизованы',
        registered: 'Зарегистрирован | Зарегистрирована | Зарегистрированы',
        new: 'Новая | Новый | Новое',
        user: 'Пользователь | Пользователей | Пользователи',
        role: 'Роль | Роль | Роли',
        number: 'Номер | Номера | Номеру',
        skill: 'Навык | Навыка | Навыки',
        project: 'Проект | Проекта | Проекты',
        performer: 'Исполнитель | Исполнителя | Исполнители',
        file: 'Файл | Файла | Файлы',
        type: 'Тип | Типа | Типы',
        is_active: 'Активен | Активна | Активны',
        is_disabled: 'Отключен | Отключена | Отключены',
        priority: 'Приоритет | Приоритета | Приоритету',
        overlap: 'Перекрытие | Перекрытия | Перекрытию',
        pool: 'Пул | Пула | Пулы',
        price: 'Цена | Цену | Цены',
        rule: 'Правило | Правил | Правила',
        title: 'Заголовок | Заголовка | Заголовки',
        document: 'Документ | Документа | Документы',
        is_deleted: 'Удален | Удалена | Удалены',
        years: 'Лет | Год | Года',
        days: 'Дней | День | Дня',
        hours: 'Часов | Час | Часа',
        minutes: 'Минут | Минута | Минуты',
        widget: 'Виджет | Виджета | Виджеты',
        tag: 'Тег | Тега | Теги',
        element: 'Элементы | Элемента | Элементы',
        expert: 'Эксперт | Эксперта | Эксперты',
        tour: 'Тур | Тура | Туры',
        item: 'Пункт | Пункта | Пункты | Пунктов',
        segment: 'Сегмент | Сегмента | Сегменты | Сегментов',
        second: 'Секунда | Секунды | Секунды',
        symbol: 'Символ | Символа | Символы'
    },
    errors: {
        fill_in: 'Ошибка заполнения',
        fio: 'Поле может содержать только буквы русского или латинского алфавитов, цифры, дефис, пробел, апостроф, точку, круглые скобки, римские цифры I, V, X. В поле недопустимо одновременно использовать русские и латинские буквы.',
        time_order: 'Время выставлено не в хронологическом порядке',
        required_field: 'Обязательно для заполнения',
        not_unique: 'Значение не уникально',
        is_reserved: 'Значение зарезервировано',
        openid_auth: 'Не удалось авторизоваться через OpenID',
        must_be_gt: 'Значение поля должно быть больше чем',
        must_be_lt: 'Значение поля должно быть меньше чем',
        must_be_ge: 'Значение поля должно быть больше или равно',
        must_be_btw: 'Значение поля должно быть в диапазоне',
        must_contain: 'Значение поля должно содержать',
        subitem_rt_required: 'Введите символьный код во всех дочерних элементах',
        subitem_rt_not_unique: 'Символьный код дочернего элемента не уникален',
        education: 'Вы сделали ошибку',
        at_least_one_must_be_enabled: 'Хотя бы одно значение должно быть выбрано',
        audio_not_supported: 'Ваш браузер не поддерживает прослушивание аудио.',
        playback_percent: 'Ошибка просмотра/прослушивания',
        network_json_status: 'Произошла ошибка, ответ сервера не содержит разъяснения',
        network_json_parse: 'Некорректный ответ сервера',
        network_not_ok_no_message: '@:(erorrs.network_json_status)',
        network_not_ok_parse: 'Произошла ошибка, некорректный ответ сервера',
        network_connect: 'Ошибка сети',
        max_limit_exceeded: 'Превышено максимальное значение',
        need_page_reload: 'Пожалуйста, перезагрузите страницу',
        json_parse: 'Ошибка при разборе JSON',
        file_reader: 'Ошибка при чтении файла',
        ajv_validation: 'Значение не соответствет спецификации',
        infinite_loop: 'Ошибка! Бесконечный цикл'
    }
}
